import React, { useEffect, useState } from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import { getProposal } from "../services/getRequest";

import Layout from "../components/layout";
import Wizard from "../components/Wizard";
import Step1 from "../components/form/steps/step-1";
import Step2 from "../components/form/steps/step-2";
import Step3 from "../components/form/steps/step-3";

import { Step1Values } from "../types/form/step-1-values.interface";
import { Step2Values } from "../types/form/step-2-values.interface";
import { Step3Values } from "../types/form/step-3-values.interface";
import { NewProposal } from "../types/form/new-proposal-data.type";

const FormWizard: React.FC<{ location: any }> = ({ location }) => {
  const [partitionKey, setPartitionKey] = useState();
  // Utilising state in order to deal with async values
  const [step1Values, setStep1Values] = useState<Step1Values>({
    sport: ["Cycling"],
    prioritisation: "Sport",
    projectType: "Sport Wales"
  });
  const [step2Values, setStep2Values] = useState<Step2Values>({supportingFiles: []});
  const [step3Values, setStep3Values] = useState<Step3Values>();
  const searchParams = new URLSearchParams(`${location.search}`);
  const [formStep, setFormStep] = useState(0);

  useEffect(() => {
    if (searchParams.has("id")) {
      getProposal(searchParams.get("id")).then((proposal: NewProposal[]) => {
        setFormStep(proposal.formStep)
        setStep1Values({
          title: proposal.title,
          sport: proposal.sport,
          prioritisation: proposal.prioritisation,
          projectType: proposal.projectType
        })
        setStep2Values({
          performanceChallenge: proposal.performanceChallenge,
          insight: proposal.insight,
          supportingFiles: proposal.files,
        })
        setStep3Values({
          outcome: proposal.desiredOutcome,
          inclusive: proposal.sustainableContribution,
          startDate: proposal.startDate,
          suggestedProjectLead: proposal.suggestedProjectLead,
          endDate: proposal.endDate,          
        });
      });
    }
    else {
      setFormStep(0)
    }
  }, []);
  return (
    <Layout>
      <h1 className="block-title">
        <FormattedMessage id="new_proposal" defaultMessage="New Proposal" />
      </h1>
      <Wizard location={location} formStep={formStep} setFormStep={setFormStep}>
        <Step1 initialValues={step1Values} />
        <Step2 initialValues={step2Values} />
        <Step3 initialValues={step3Values} />
      </Wizard>
    </Layout>
  );
};

export default FormWizard;
