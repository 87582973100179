import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import { useWizard } from "../Wizard";

interface NavigationProps {
  onPreviousClick: (event: React.MouseEvent<HTMLElement>) => void;
  onDiscardClick: (event: React.MouseEvent<HTMLElement>) => void;
  onSaveAndExit: (event: React.MouseEvent<HTMLElement>) => void;
}

const Navigation = ({
  onPreviousClick,
  onDiscardClick,
  onSaveAndExit,
}: NavigationProps) => {
  const { isFirstStep, isLastStep } = useWizard();

  return (
    <div className="wizard-nav my-5">
      <div className="wizard-nav__primary">
        <button type="submit" className="btn btn-lg btn-primary">
          {isLastStep ? (
            <FormattedMessage id="submit" defaultMessage="Submit" />
          ) : (
            <FormattedMessage
              id="save_and_continue"
              defaultMessage="Save and Continue"
            />
          )}
          <img
            className="case-study-item__link-icon ms-1"
            src="/img/chevron-right.svg"
            alt=""
          />
        </button>
      </div>
      <div className="wizard-nav__exits mt-3">
        <button
          type="button"
          onClick={onPreviousClick}
          className="btn btn-sm btn-outline-secondary me-3"
          disabled={isFirstStep}
        >
          <FormattedMessage id="previous" defaultMessage="Previous" />
        </button>
        <button
          type="button"
          onClick={onSaveAndExit}
          className="btn btn-sm btn-outline-secondary me-3"
        >
          <FormattedMessage id="save_and_exit" defaultMessage="Save and Exit" />
        </button>
        <button
          type="button"
          onClick={onDiscardClick}
          className="btn btn-sm btn-outline-secondary"
        >
          <FormattedMessage id="discard" defaultMessage="Discard" />
        </button>
      </div>
    </div>
  );
};

export default Navigation;
