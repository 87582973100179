import React from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import Select from "react-select";

import { Step1Values } from "../../../types/form/step-1-values.interface";
import { NewProposal } from "../../../types/form/new-proposal-data.type";
import { Prioritisation } from "../../../types/form/prioritisation.enum";
import SportSingleSelect from "../sport-single-select";
import PrioritisationSelect from "../prioritisation-select";
import ProjectTypeSelect from "../project-type-select";
import sportsOptions from "../../../types/form/sports-options.const";

export interface Props extends React.HTMLProps<HTMLDivElement> {
  initialValues: Step1Values;
}

const Step1 = () => {
  const {
    values: { sport, prioritisation, title, projectType },
    setFieldValue,
    handleBlur,
    handleChange,
  } = useFormikContext<NewProposal>();

  const intl = useIntl();

  const step1 = useStaticQuery(
    graphql`
      query Step1Query {
        allMarkdownRemark(filter: { fields: { slug: { eq: "/step-1/" } } }) {
          nodes {
            frontmatter {
              title
              cy {
                step1Title
                projectTitle {
                  question
                }
                sport {
                  help
                  question
                }
                project {
                  help
                  question
                  options {
                    option_1
                    option_2
                    option_3
                  }
                }
                support {
                  help
                  question
                  options {
                    support_1
                    support_2
                    support_3
                  }
                }
              }
              en {
                step1Title
                projectTitle {
                  question
                }
                sport {
                  help
                  question
                }
                project {
                  help
                  question
                  options {
                    option_1
                    option_2
                    option_3
                  }
                }
                support {
                  help
                  question
                  options {
                    support_1
                    support_2
                    support_3
                  }
                }
              }
            }
            id
          }
        }
      }
    `
  );

  const data = step1.allMarkdownRemark.nodes[0].frontmatter;
  const { step1Title } = data[intl.locale];
  const projectTitleText = data[intl.locale].projectTitle;
  const sportText = data[intl.locale].sport;
  const projectText = data[intl.locale].project;
  const supportText = data[intl.locale].support;

  return (
    <section className="row">
      <div className="col-12">
        <h2>
          <FormattedMessage id="step_1" defaultMessage="Step 1 of 3" />
        </h2>
        <h3 className="anchor-title">{step1Title}</h3>
        <Form.Group className="mb-3" controlId="title">
          <Form.Label>{projectTitleText.question}</Form.Label>
          <Form.Control
            onChange={handleChange}
            onBlur={handleBlur}
            value={title}
            maxLength={projectTitleText.charLimit}
            required
          />
          {!!projectTitleText.charLimit && (
            <div id="projectTitle-char-limit" className="form-text">
              {projectTitleText.charLimit} character limit
            </div>
          )}
          {!!projectTitleText.help && (
            <div id="projectTitle-help" className="form-text">
              {projectTitleText.help}
            </div>
          )}
        </Form.Group>
        <PrioritisationSelect
          name="prioritisation"
          label={projectText.question}
          value={prioritisation}
          controlId="prioritisation"
          selectAllEnabled={false}
          onChange={handleChange}
          onBlur={handleBlur}
        ></PrioritisationSelect>

        <Form.Group className="mb-3" controlId="sport">
          <Form.Label>{sportText.question}</Form.Label>
          {prioritisation !== Prioritisation.SYSTEM ? (
            <SportSingleSelect
              name="sport"
              selectAllEnabled={false}
              onChange={handleChange}
              value={sport[0]}
            ></SportSingleSelect>
          ) : (
            <Select
              name="sport"
              isMulti
              defaultValue={sport?.map((sport: string) => {
                return { value: sport, label: sport };
              })}
              onChange={(selectedOptions) =>
                setFieldValue(
                  "sport",
                  selectedOptions.map((opt) => opt.value)
                )
              }
              options={sportsOptions}
              placeholder={intl.formatMessage({
                id: "select_multiple_sports",
                defaultMessage: "Select sports",
              })}
            />
          )}

          {!!sportText.help && (
            <div id="sport-help" className="form-text">
              {sportText.help}
            </div>
          )}
        </Form.Group>
        <Form.Group>
          <ProjectTypeSelect
            name="projectType"
            label={supportText.question}
            selectAllEnabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            value={projectType}
          ></ProjectTypeSelect>
        </Form.Group>
      </div>
    </section>
  );
};

export default Step1;
