import React from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";

import { Step2Values } from "../../../types/form/step-2-values.interface";
import { NewProposal } from "../../../types/form/new-proposal-data.type";
import ProjectFiles from "../../project/project-files";
import useProject from "../../../hooks/useProject";

export interface Props extends React.HTMLProps<HTMLDivElement> {
  initialValues: Step2Values;
}
// @ts-ignore TODO investigate if need props for values when returning to form
const Step2 = (props: Props) => {
  let searchParams = new URLSearchParams(location.search);

  const projectId: string = searchParams.has("id")
    ? searchParams.get("id") || ""
    : "";

  const { projectQuery } = useProject({ id: projectId });

  const { handleBlur, handleChange, values } = useFormikContext<NewProposal>();
  const intl = useIntl();

  const step2 = useStaticQuery(
    graphql`
      query Step2Query {
        allMarkdownRemark(filter: { fields: { slug: { eq: "/step-2/" } } }) {
          nodes {
            frontmatter {
              title
              en {
                step2Title
                performance {
                  charLimit
                  help
                  question
                }
                insight {
                  charLimit
                  help
                  question
                  supporting_files {
                    help
                    question
                  }
                }
              }
              cy {
                step2Title
                performance {
                  charLimit
                  help
                  question
                }
                insight {
                  charLimit
                  help
                  question
                  supporting_files {
                    help
                    question
                  }
                }
              }
            }
            id
          }
        }
      }
    `
  );

  const data = step2.allMarkdownRemark.nodes[0].frontmatter;
  const { insight, performance, step2Title } = data[intl.locale];

  return (
    <section className="row">
      <div className="col-12">
        <h2>
          <FormattedMessage id="step_2" defaultMessage="Step 2 of 3" />
        </h2>
        <h3 className="anchor-title">{step2Title}</h3>
        <Form.Group className="mb-3" controlId="performanceChallenge">
          <Form.Label>{performance.question}</Form.Label>
          <Form.Control
            as="textarea"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.performanceChallenge}
            maxLength={performance.charLimit}
            required
          />
          {!!performance.charLimit && (
            <div id="performance-char-limit" className="form-text">
              {performance.charLimit} character limit
            </div>
          )}
          {!!performance.help && (
            <div id="performance-help" className="form-text">
              {performance.help}
            </div>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="insight">
          <Form.Label>{insight.question}</Form.Label>
          <Form.Control
            as="textarea"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.insight}
            maxLength={insight.charLimit}
            required
          />
          {!!insight.charLimit && (
            <div id="insight-char-limit" className="form-text">
              {insight.charLimit} character limit
            </div>
          )}
          {!!insight.help && (
            <div id="insight-help" className="form-text">
              {insight.help}
            </div>
          )}
        </Form.Group>
        {projectId && (
          <ProjectFiles files={projectQuery.data?.files} id={projectId} />
        )}
      </div>
    </section>
  );
};

export default Step2;
