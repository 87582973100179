import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useIntl } from "gatsby-plugin-intl";

import { ProjectType } from "../../types/form/project-type.enum";
const authCheck = `/.auth/me`;
const userURL = `/api/user`;

interface Props {
  name: string;
  label: string;
  controlId?: string;
  value: ProjectType | string | undefined;
  selectAllEnabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ProjectTypeSelect = ({
  onChange,
  onBlur,
  value,
  label,
  controlId,
  selectAllEnabled,
}: Props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [practitioner, setPractitioner] = useState(false);
  const [WIPSUser, setWIPSUser] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const authResponse = await fetch(authCheck);
      const authJson = await authResponse.json();
      if (authJson.clientPrincipal) {
        const res = await fetch(userURL);
        const json = await (res.json());
        setLoggedIn(json.roles.includes("authorised"));
        setPractitioner(json.roles.includes("practitioner"));
        setWIPSUser(json.roles.includes("WIPS"));
        
      }
    };
    fetchData();
  }, [loggedIn]);
  const intl = useIntl();

  return (
    <Form.Group className="mb-3" controlId={controlId}>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        aria-label={intl.formatMessage({
          id: "select_a_projecttype",
          defaultMessage: "Select a projecttype",
        })}
        onChange={(e) => onChange(e)}
        onBlur={(e) => onBlur(e)}
        value={value}
        required
        id={`projectType`}
      >
        {Object.values(ProjectType).map((val) => {
          const isDisabled = (val.includes("WIPS") && !(WIPSUser || practitioner ))
          return (
            <option key={val} value={val} disabled={isDisabled}>
              {intl.formatMessage({
                id: val,
              })}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
};

export default ProjectTypeSelect;
