import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl, injectIntl, FormattedMessage } from "gatsby-plugin-intl";
import dayjs from "dayjs";

import { Step3Values } from "../../../types/form/step-3-values.interface";
import { NewProposal } from "../../../types/form/new-proposal-data.type";

export interface Props extends React.HTMLProps<HTMLDivElement> {
  initialValues: Step3Values;
}

const Step3 = (props: Props) => {
  const intl = useIntl();
  const endDate = useRef(null);
  const startDate = useRef(null);
  const startDateField: HTMLInputElement = ReactDOM.findDOMNode(
    startDate.current
  ) as HTMLInputElement;
  const endDateField: HTMLInputElement = ReactDOM.findDOMNode(
    endDate.current
  ) as HTMLInputElement;

  const invalidMessage = intl.formatMessage({
    id: "date_invalid",
    defaultMessage: "Start date must be earlier than end date",
  });

  const { handleBlur, handleChange, values, setFieldValue, dirty } =
    useFormikContext<NewProposal>();

  // Replace onChange formik event for date fields so that custom validation can happen
  const dateCheck = (date: { target: { id: string; value: any } }) => {
    if (!!startDate.current && !!endDate.current) {
      const startDateDayJS: dayjs.Dayjs = dayjs(startDate.current.value);
      const endDateDayJS: dayjs.Dayjs = dayjs(endDate.current.value);

      setFieldValue(date.target.id, date.target.value);
      if (startDateDayJS.isAfter(endDateDayJS)) {
        startDateField.setCustomValidity(invalidMessage);
        endDateField.setCustomValidity(invalidMessage);
      } else {
        if (!!startDateField && endDateField) {
          startDateField.setCustomValidity("");
          endDateField.setCustomValidity("");
        }
      }
    }
  };
  useEffect(() => {
    // Initial check when form is clean for date validity as onChange isn't called on value change
    if (!dirty) {
      if (
        !!startDate.current &&
        !!endDate.current &&
        dayjs(startDate.current.value).isAfter(dayjs(endDate.current.endDate))
      ) {
        startDateField.setCustomValidity(invalidMessage);
        endDateField.setCustomValidity(invalidMessage);
      }
    }
  }, []);

  const step = useStaticQuery(
    graphql`
      query Step3Query {
        allMarkdownRemark(filter: { fields: { slug: { eq: "/step-3/" } } }) {
          nodes {
            frontmatter {
              title
              en {
                step3Title
                outcome {
                  charLimit
                  help
                  question
                }
                inclusive {
                  charLimit
                  help
                  question
                }
                projectLead {
                  charLimit
                  help
                  question
                }
              }
              cy {
                step3Title
                outcome {
                  charLimit
                  help
                  question
                }
                inclusive {
                  charLimit
                  help
                  question
                }
                projectLead {
                  charLimit
                  help
                  question
                }
              }
            }
            id
          }
        }
      }
    `
  );

  const data = step.allMarkdownRemark.nodes[0].frontmatter;
  const { outcome, inclusive, projectLead, step3Title } = data[intl.locale];

  return (
    <section className="row">
      <div className="col-12">
        <h2>
          <FormattedMessage id="step_3" defaultMessage="Step 3 of 3" />
        </h2>
        <h3 className="anchor-title">{step3Title}</h3>
        <Form.Group className="mb-3" controlId="outcome">
          <Form.Label>{outcome.question}</Form.Label>
          <Form.Control
            as="textarea"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.outcome}
            maxLength={outcome.charLimit}
            required
          />
          {!!outcome.charLimit && (
            <div id="outcome-char-limit" className="form-text">
              {outcome.charLimit} character limit
            </div>
          )}
          {!!outcome.help && (
            <div id="outcome-help" className="form-text">
              {outcome.help}
            </div>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="inclusive">
          <Form.Label>{inclusive.question}</Form.Label>
          <Form.Control
            as="textarea"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.inclusive}
            maxLength={inclusive.charLimit}
            required
          />
          {!!inclusive.charLimit && (
            <div id="inclusive-char-limit" className="form-text">
              {inclusive.charLimit} character limit
            </div>
          )}
          {!!inclusive.help && (
            <div id="inclusive-help" className="form-text">
              {inclusive.help}
            </div>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="suggestedProjectLead">
          <Form.Label>{projectLead.question}</Form.Label>
          <Form.Control
            as="textarea"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.suggestedProjectLead}
            maxLength={projectLead.charLimit}
            required
          />
          {!!projectLead.charLimit && (
            <div id="projectLead-char-limit" className="form-text">
              {projectLead.charLimit} character limit
            </div>
          )}
          {!!projectLead.help && (
            <div id="projectLead-help" className="form-text">
              {projectLead.help}
            </div>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="startDate">
          <Form.Label>
            {" "}
            <FormattedMessage id="start_date" defaultMessage="Start Date" />
          </Form.Label>
          <Form.Control
            type="date"
            onChange={dateCheck}
            ref={startDate}
            value={new Date(values.startDate).toLocaleDateString('fr-CA')}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="endDate">
          <Form.Label>
            {" "}
            <FormattedMessage id="end_date" defaultMessage="End Date" />
          </Form.Label>
          <Form.Control
            type="date"
            ref={endDate}
            onChange={dateCheck}
            value={new Date(values.endDate).toLocaleDateString('fr-CA')}
            required
          />
        </Form.Group>
      </div>
    </section>
  );
};

export default Step3;
