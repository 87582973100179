import React, { useState } from "react";
import { Formik } from "formik";
import { navigate, useIntl } from "gatsby-plugin-intl";

import {
  deleteProject,
  postProject,
  postProposal,
  updateProject,
} from "../../services/getRequest";
import Navigation from "./Navigation";
import { NewProposal } from "../../types/form/new-proposal-data.type";
import { Alert } from "react-bootstrap";

export const WizardContext = React.createContext({
  activeStep: 1,
  isFirstStep: true,
  isLastStep: false,
});
export const useWizard = () => React.useContext(WizardContext);

interface WizardProps {
  children: React.ReactNode;
  location: object;
  formStep: number;
}

const Wizard = ({ children, location, formStep, setFormStep }: WizardProps) => {
  const [formError, setErrorMessage] = useState<string>();
  const [errorShow, setErrorShow] = useState(false);
  let searchParams = new URLSearchParams(location.search);
  const [id, setId] = useState(
    searchParams.get("id") ? searchParams.get("id") : null
  );
  const steps = React.Children.toArray(children);

  const initialValues: any = steps.reduce(
    (acc, current) => ({
      ...(acc as {}),
      ...(current.props.initialValues as {}),
    }),
    {}
  );

  const step = steps[formStep];
  const totalSteps = steps.length;
  const isFirstStep = formStep === 0;
  const isLastStep = formStep === 2;
  const isSecondStep = formStep === 1;
  const intl = useIntl();
  const handleSubmit = async (values: NewProposal) => {
    let formData: NewProposal = values;
    if (isFirstStep) {
      let body = {
        title: formData.title,
        status: "Evaluation - Stage 1",
        formStep: formData.formStep,
        submitted: false,
        projectType: formData.projectType,
        prioritisation: formData.prioritisation,
      };
      // Prioritisation determines whether or not to use the multi-select field, API expects
      // an array, format string to array if it's not system
      if (formData.prioritisation === "System") {
        body.sport = formData.sport;
      } else {
        body.sport =
          typeof formData.sport == "string"
            ? new Array(formData.sport)
            : formData.sport;
      }
      body.formStep = 1;

      if (id == null) {
        body.files = [];
        setErrorMessage("");
        setErrorShow(false);
        postProject(body)
          .then((response) => {
            setErrorMessage("");
            setErrorShow(false);

            navigate(`/form-wizard?id=${response.id}`);
            setId(response.id);
            setFormStep(formStep + 1);
          })
          .catch((errorMessage) => {
            console.log(errorMessage);
            setErrorMessage(errorMessage.message);
            setErrorShow(true);
          });
      }
      if (id !== null) {
        updateProject(id, body)
          .then((response) => {
            navigate(`/form-wizard?id=${response.id}`);
            setId(response.id);
            setFormStep(formStep + 1);
          })
          .catch((errorMessage) => {
            setErrorMessage(errorMessage.message);
            setErrorShow(true);
          });
      }
    }

    if (isSecondStep) {
      formData.formStep = 1;
      let body = {
        performanceChallenge: formData.performanceChallenge,
        insight: formData.insight,
      };
      updateProject(id, body)
        .then((response) => {
          navigate(`/form-wizard?id=${response.id}`);
          setId(response.id);
          setFormStep(formStep + 1);
        })
        .catch((errorMessage) => {
          setErrorMessage(errorMessage.message);
          setErrorShow(true);
        })
        .then((error) => {
          console.log(error);
        });
    }
    if (isLastStep) {
      let body = {
        desiredOutcome: formData.outcome,
        sustainableContribution: formData.inclusive,
        suggestedProjectLead: formData.suggestedProjectLead,
        startDate: Date.parse(formData.startDate),
        endDate: Date.parse(formData.endDate),
        submitted: true,
        formStep: 2,
      };
      updateProject(id, body)
        .then((response) => {
          navigate("/form-submit-confirmation/");
          setId(response.id);
        })
        .catch((errorMessage) => {
          setErrorMessage(errorMessage.message);
          setErrorShow(true);
        });
    }
  };
  const handleClickPrevious = () => {
    if (isFirstStep) return;
    setFormStep(formStep - 1);
  };

  const handleClickDiscard = () => {
    if (
      window.confirm(
        intl.formatMessage({
          id: "discard_proposal",
          defaultMessage: "This proposal will be discarded",
        })
      )
    ) {
      deleteProject(id).then((response) => {
        searchParams.delete("id");
        navigate("/");
      });
    }
  };

  const handleSaveAndExit = async (currentValues: NewProposal) => {
    const formData: NewProposal = currentValues;
    let body = {
      title: formData.title,
      formStep: formData.formStep,
      prioritisation: formData.prioritisation,
      projectType: formData.projectType,
      performanceChallenge: formData.performanceChallenge,
      insight: formData.insight,
      desiredOutcome: formData.outcome,
      sustainableContribution: formData.inclusive,
      suggestedProjectLead: formData.suggestedProjectLead,
      startDate: formData.startDate && Date.parse(formData.startDate),
      endDate: formData.endDate && Date.parse(formData.endDate),
      submitted: false,
      formStep: formData.formStep,
    };
    if (id == null) {
      body.files = [];
      postProject(body)
        .then((_response: any) => {
          searchParams.delete("id");
          navigate("/");
        })
        .catch((errorMessage) => {
          setErrorMessage(errorMessage.message);
          setErrorShow(true);
        });
    }
    if (id !== null) {
      updateProject(id, body)
        .then((_response: any) => {
          searchParams.delete("id");
          navigate("/");
        })
        .catch((errorMessage) => {
          setErrorMessage(errorMessage.message);
          setErrorShow(true);
        });
    }
  };

  return (
    <WizardContext.Provider value={{ formStep, isFirstStep, isLastStep }}>
      <Alert variant="danger" show={errorShow}>
        <Alert.Heading>Error</Alert.Heading>
        <p>{formError}</p>
      </Alert>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        location={location}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            {step}
            <Navigation
              onPreviousClick={handleClickPrevious}
              onDiscardClick={handleClickDiscard}
              onSaveAndExit={() => handleSaveAndExit(formik.values)}
            />
          </form>
        )}
      </Formik>
    </WizardContext.Provider>
  );
};

export default Wizard;
